Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.btnExampleTitle = "CLICK ME";
exports.tabsNames=["Buying Requests","Products","Orders","Offers","Wish Requests"]

exports.subtabsMapping = {
  "Buying Requests":[],
  Products:["All Products","Hidden Products"],
  Orders: ['Pending Orders', 'Accepted Orders', 'Rejected Orders'],
  Offers: ['Pending Offers', 'Accepted Offers', 'Rejected Offers'],
  'Wish Requests': ['Pending Requests', 'Accepted Requests', 'Rejected Requests'],
};
exports.filterDateLabels={
  "Wish Requests":"Request Date",
  "Offers":"Offer Date",
  "Orders":"Order Date",
  "Buying Requests":"Listed Date",

}
exports.priceRangesLabels={
  "Offers":"Listed Range",
  "Orders":"Listed Price",
  "Buying Requests":"Listed Price",
  "Products":"Listed Range"
}
exports.sortingOptions={
  "Wish Requests":[
    {value:"recent",label:"Recent"},
    {value:"request_date_new_to_old",label:"Request Date (New to Old)"},
    {value:"request_date_old_to_new",label:"Request Date (Old to New)"},
    {value:"assign_date_new_to_old",label:"Assign Date (New to Old)"},
    {value:"assign_date_old_to_new",label:"Assign Date (Old to New)"},
  ],
  "Buying Requests":[
    {value:"recent",label:"Recent"},
    {value:"price_high_to_low",label:"Price: High to Low"},
    {value:"price_low_to_high",label:"Price: Low to High"},
    {value:"requests_hight_to_low",label:"Requests: High to Low"},
    {value:"nerequests_low_to_high",label:"Requests: Low to High"},
  ],
  "Products":[
    {value:"recent",label:"Recent"},
    {value:"sold_high_to_low",label:"Sold: High to Low"},
    {value:"sold_low_to_high",label:"Sold: Low to High"},
    {value:"offers_high_to_low",label:"Offers: High to Low"},
    {value:"offers_low_to_high",label:"Offers: Low to High"},

  ]
}
exports.tabEndPoints={
  "Buying Requests":"/bx_block_catalogue/catalogues?show_buying_requests=true",
  Products:"/bx_block_catalogue/catalogues",
  Orders:"/bx_block_order_management/shopper_orders",
  Offers:"/bx_block_request_management/offer_requests/fetch_shopper_offer",
  "Wish Requests":"/bx_block_request_management/wish_requests/fetch_wish_lists"
}
// Customizable Area End