Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.tabelHeadings = [
  { id: 1, label: "Request ID", value: "request_id" },
  { id: 2, label: "Product Image", value: "product_image" },
  { id: 3, label: "Date", value: "date" },
  { id: 4, label: "Product Name", value: "product_name" },
  { id: 5, label: "Category", value: "category" },
  { id: 6, label: "Modal Number", value: "modal_number" },
  { id: 7, label: "Brand", value: "brand" },
  { id: 8, label: "Status", value: "status" },
  { id: 9, label: "Actions", value: "actions" }
];

exports.offerIdLabel = "Offer ID";
exports.productImageLabel = "Product Image";
exports.dateLabel = "Date";
exports.categoryLabel = "Category";
exports.productNameLabel = "Product Name";
exports.modalNumberLabel = "Modal Number";
exports.listedPriceLabel = "Listed Price";
exports.offerPriceLabel = "Offer Price";
exports.statusLabel = "Status";
exports.actionLabel = "Action";
exports.profile = "Profile";
exports.offers = "Offers";
exports.filter = "Filter";
exports.offerListApiEndPoint = "bx_block_request_management/offer_requests";
exports.methodType = "GET";
exports.deleteOfferApiEndPoint = "bx_block_request_management/offer_requests/";
exports.offerFilterApiEndPoint = "bx_block_request_management/offer_requests/load_filter";
exports.httpGetType = "GET";
exports.deleteMethodType = "DELETE";





exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.endPointgetcategoriesArrayData='bx_block_categories/categories'
exports.filteredCatalogues=(categoryId)=>`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
exports.endPointWishDetailsApi=(wishId)=>`bx_block_request_management/wish_requests/${wishId}`
exports.endPointAddToCart ='bx_block_shopping_cart/order_items'
exports.homeText='Home'
exports.my_Wishes='My Wishes'
exports.details='Details'
exports.request_Id='Request ID:'
exports.requested_Date='Requested Date'
exports.productDetails='Product Details'
exports.category='Category'
exports.brand='Brand'
exports.year_of_Production='Year of Production'
exports.model_Number='Model Number'
exports.description='Description'
exports.delete_Request='Delete Request'
exports.confirmation='Confirmation'

exports.wear='Wear'
exports.condition='Condition'
exports.material='Material'
exports.use='Use'
exports.water_Resistance='Water Resistance'
exports.packages='Packages'
exports.dustBag='Dust Bag'

exports.request_Details='Request Details'
exports.buyNow='Buy Now'


exports.are_you_sure_you_want_to_delete_the_wish_request=`Are you sure you want to delete the wish request?`
exports.not_now='Not now'
exports.yes='Yes'
exports.back='Back'
exports.loremText=`Lorem ipsum dolor sit amet consectetur. Id vel dictum amet malesuada id vestibulum vitae consequat. Fermentum convallis sit nam interdum et netus`
exports.homeText="Home"
exports.buyNowApiEndPoint="bx_block_shopping_cart/order_items"
// Customizable Area End