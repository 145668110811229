import React from "react";
import { styled } from "@mui/system";
import { Modal, Box, Typography, IconButton, DialogActions, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FooterButton = styled("button")({
    width: "155px",
    height: "56px",
    padding: "16px 0px",
    borderRadius: "8px",
    color: "white",
    border: "none",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    fontWeight: 800,
    cursor: "pointer",
  });
const DeleteVariantPopup = ({open,handleClose,handleLogOut}:any) => {

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{              
          maxWidth: '100%',
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#EFEEE9",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            maxWidth: "600px",
            width: "600px",
            outline: "none",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              style={{ height: "24px", width: "24px", float: "right" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              style={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "22px",
                fontWeight: 600,
                lineHeight: "32px",
                letterSpacing: "-0.11999999731779099px",
                textAlign: "center",
                color: "#53503F",
              }}
              component="h2"
            >
              Confirmation
            </Typography>
            <Typography
              id="modal-modal-description"
              style={{
                marginTop:"25px",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "34px",
                letterSpacing: "-0.11999999731779099px",
                textAlign: "center",
                maxWidth: "355px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                color: "#53503F",
              }}
              sx={{ mt: 2 }}
            >
              Are you sure you want to delete 
              the 1 product variant?
            </Typography>
            <div style={{marginTop:"30px",marginBottom:"15px",display:"flex",gap:"50px"}}>
            <FooterButton data-test-id="cancel-button"  style={{fontSize: '18px',backgroundColor:"#EFEEE9",color:"#53503F",border:"1px solid #53503F"}} onClick={handleClose}>Not now</FooterButton>
            <FooterButton data-test-id="yes-button" style={{fontSize: '18px',backgroundColor:"#53503F"}}  onClick={handleLogOut}>Yes</FooterButton>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteVariantPopup;
