import React from "react";
// Customizable Area Start

import ContactusController, {
  Props,
  configJSON,
  QueryInterface
} from "./ContactusController";

import {
  Box,
  styled,
  Typography,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
import { crossIcon, rightIcon, arrowCurve,leftFirstCircle,leftTopSecondCircle ,rightBigCircle,rightSmallCircle} from "./assets"
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
     // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 handleReturnInputField = (label: string, placeholder: string, stateValue: string, state: string) => {
  return (
      <Box className='singleInputParentBox'>
          <LabelTxt >
              {label}
          </LabelTxt>
          <InputFields
              fullWidth
              error={state == 'email' && this.state.emailErr}
              placeholder={placeholder}
              helperText={state == 'email' && this.state.emailErr ? configJSON.emailErr : ''}
              value={stateValue?.trimStart()}
              variant="outlined"
              onChange={(event) => this.handleChangeInput(event.target.value, state)}
              data-test-id={state}
          />

      </Box>
  )
}
handleReturnMultiLineInputField = (label: string, placeholder: string, stateValue: string, state: string) => {
  return (
      <Box className='singleInputParentBox'>
          <LabelTxt >
              {label}
          </LabelTxt>
          <InputFieldsMultiLine
              fullWidth
              error={this.state.messageErr}
              placeholder={placeholder}
              value={stateValue?.trimStart()}
              variant="outlined"
              onChange={(event) => this.handleChangeMultiLineInput(event.target.value, state)}
              data-test-id='inputFieldsMultiLine'
              multiline
              InputProps={{
                  disableUnderline: true,
              }}
              inputProps={{
                  style: {
                      resize: "none",
                      height: '140px',
                      borderRadius: '8px',
                  },
              }}
          />

          <Typography style={{ ...styles.messageWordLimitText }} className="messageWordLimitText">{this.state.message.trim().length}/200</Typography>
      </Box>
  )
}
handleReturnSelectField = (optionArrList: QueryInterface[], label: string, placeholder: string, stateValue: QueryInterface | {} | undefined | null, state: string) => {
  return (
      <Box className='singleInputParentBox'>
          <LabelTxt >
              {label}
          </LabelTxt>
          <ReactSelectField
              options={optionArrList}
              placeholder={placeholder}
              value={stateValue}
              data-test-id='selectField'
              onChange={(event) => this.handleChangeSelect(event, state)}
              classNamePrefix={'custom-select'}
              isDisabled={optionArrList.length == 0}
              styles={{

                  menu: (provided) => ({
                      ...provided,
                      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                      border: '1px solid #53503F',
                      borderRadius: '4px',
                      zIndex: 10000,
                      overflow: 'hidden',
                      marginTop: '3.93px',

                  }),
                  option: (provided, state) => ({
                      ...provided,
                      gap: '8px',
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      paddingRight: '16px',
                      backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                      paddingTop: '12px',
                      paddingLeft: '16px',
                      paddingBottom: '12px',
                      color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                      fontWeight: state.isSelected ? 600 : 400,
                      fontSize: '12px',
                      fontFamily: "'Montserrat', sans-serif",
                      "&:hover": {
                          backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                      }

                  }),

              }}
          />
      </Box>
  )
}
handleReturnPhoneNumberField = () => {
  return (
      <Box className='singleInputParentBox'>
          <LabelTxt >
              {configJSON.phone_Number}
          </LabelTxt>
          <PhoneNumStyle
              sx={{ border: this.state.new_phone_numberErr ? '1px solid #DC2626' : '' }}
              data-test-id="txtInputPhonenumber"
              className="custominput"
              value={this.state.phoneNumber}
              countries={[]}
              international
              onChange={(event) => this.handleChangePhoneNumber(event)}

          />
          {this.state.new_phone_numberErr && <Typography data-test-id='phoneNumberErr' sx={styles.errorText}>{this.state.new_phone_numberErrText !== '' ? this.state.new_phone_numberErrText : configJSON.phoneErr}</Typography>}
      </Box>
  )
}
handleSuccessModal = (isOpen: boolean, state: string, subHeading:string,heading: string) => {
  return (
      <Box >
          <Modal
              open={isOpen}
              onClose={() => this.setState((prevState) => ({ ...prevState, [state]: false,successMessage: '',}))}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              data-test-id={state}
              sx={{ backdropFilter: "blur(5px)" }}
          >
              <ResponseModalBox className={'responseModalBox'}>
                  <Box data-test-id='crossIconBox2' className="crossIconBox" onClick={() => this.setState((prevState) => ({ ...prevState, [state]: false,successMessage: ''}))}>
                      <img src={crossIcon} alt="" />
                  </Box>
                  <><Typography className="modalHeading">{heading}</Typography>
                  <Typography className='contentText1'>{subHeading}</Typography>
                      <ButtonParentBox>
                          <MainButton data-test-id='go_to_Home' variant="contained" onClick={()=>this.handleNavigate('Home')}>{configJSON.go_to_Home}</MainButton>
                      </ButtonParentBox></>
              </ResponseModalBox>
          </Modal>
      </Box>
  )
}
    // Customizable Area End
  render() {
    // Customizable Area Start
    const profile = this.state.profileData.attributes
    return (
      <Container>
          {profile?.role_name==='personal_shopper'||this.state.userRole==='personal_shopper'?<ShopperHeader/> :<AppHeader data-test-id='appHeader' count={this.state.cartData?.length}/>}
          <OuterBox>
              <ArrowTopBox>
                  <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.homeText}</Typography>
                  <img src={rightIcon} alt="rightIcon" />
                  <Typography className="arrowSecondText"> {configJSON.ContactUs} </Typography>
              </ArrowTopBox>
              <OuterBoxFirst>
                  <MainBoxFirst>
                      <TopBoxContainer>
                          <img src={leftFirstCircle} alt="" className="leftFirstCircle" />
                          <img src={leftTopSecondCircle} alt=""  className="leftTopSecondCircle" />

                          <Box className='profileHeadingBox'>
                              <Typography className="profile">{configJSON.ContactUs}</Typography>
                              <Typography className="topSubHeading">{configJSON.Fill_in_the_form_below_and_we_will_get_back_to_you_as_soon_as_possible}</Typography>
                          </Box>
                          <img src={rightSmallCircle} alt="" className="rightSmallCircle" />
                          <img src={rightBigCircle} alt=""  className="rightBigCircle" />
                      </TopBoxContainer>
                      <FormBoxMain>
                          <ProfileDetailsBoxContainer>
                              <Box className='fourInputMainBox'>
                                  <Box className='TwoInputBox'>
                                      {this.handleReturnInputField(configJSON.first_Name, configJSON.Your_first_name, this.state.first_Name, 'first_Name')}
                                      {this.handleReturnInputField(configJSON.last_Name, configJSON.Your_last_name, this.state.last_Name, 'last_Name')}
                                  </Box>
                                  <Box className='TwoInputBox'>
                                      {this.handleReturnInputField(configJSON.email, configJSON.your_Email, this.state.email, 'email')}
                                      {this.handleReturnPhoneNumberField()}
                                  </Box>
                              </Box>
                              <Box className='messageOuterBox'>
                                  {this.handleReturnMultiLineInputField(configJSON.message, configJSON.writeHere, this.state.message, 'message')}
                              </Box>
                              <Box className='selectOuterBox'>
                                  {this.handleReturnSelectField(configJSON.queryArray, configJSON.type_of_query, configJSON.Select, this.state.query, 'query')}
                              </Box>
                              <SendMessageButtonParentBox>
                                  <SendMessageButton data-test-id='sendMessage' variant="contained" disabled={this.handleDisable()} onClick={()=>this.handleSendMessage()}>{configJSON.sendMessage}</SendMessageButton>
                              </SendMessageButtonParentBox>
                              <img src={arrowCurve} alt="" className="curveImage" />
                          </ProfileDetailsBoxContainer>
                      </FormBoxMain>
                  </MainBoxFirst>
              </OuterBoxFirst>
          </OuterBox>
          {this.handleSuccessModal(this.state.successPopupOpen, 'successPopupOpen',this.state.successMessage, configJSON.successMessageHeading)}
          {profile?.role_name==='personal_shopper'||this.state.userRole==='personal_shopper'?<ShopperFooter/>:
           <Footer data-test-id="footerTest" openLoginModal={this.openLoginModal}  handleCategoryData={this.handleCategoryData} categoriesList={this.state.categoriesArrayData} /> }
      </Container>
  );
    // Customizable Area Start
  }
}

// Customizable Area Start

const Container = styled(Box)({
  backgroundColor: '#EDE6DC',
})
const ArrowTopBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  paddingLeft: '6px',
  "& .arrowHomeText": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      fontWeight: 400,
      color: '#53503F',
      cursor: 'pointer'
  },
  "& .arrowSecondText": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      fontWeight: 600,
      color: '#53503F'
  }

})
const OuterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '46.6px 40px 67.76px 40px',
  "@media (max-width: 470px)": { padding: '46.6px 8px 67.76px 8px', }
})
const OuterBoxFirst = styled(Box)({
  display: 'flex',
  paddingTop: '30px',
  boxSizing: 'border-box',
  justifyContent: 'center',
  "@media (max-width: 890px)": { padding: '50.6px 0px 50.6px 0px', }
})
const MainBoxFirst = styled(Box)({
  display: 'flex',
  width: '1196px',
  alignItems: 'center',
  flexDirection: 'column',
  "@media (max-width: 1050px)": { width: '100%', }
})
const TopBoxContainer = styled(Box)({
  height: '400px',
  backgroundColor: '#53503F',
  position: 'relative',
  width: '100%',
  borderRadius: '4px',
   
  "& .leftFirstCircle": {
      left: 0,
      position: 'absolute',
      top: 0
  },
  "& .leftTopSecondCircle": {
      left: '70px',
      position: 'absolute',
      top: '70px'
  },
  "& .rightBigCircle":{
      right: 0,
      position: 'absolute',
      bottom: 0
  },
  "& .rightSmallCircle":{
      right: '60px',
      position: 'absolute',
      bottom: '60px'
  },
  "& .profileHeadingBox": {
      width: "100%",
      paddingTop: '58.56px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24.74px'

  },
  "& .profile": {
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 600,
      color: '#EDE6DC',
      fontFamily: "'Montserrat', sans-serif",
      lineHeight: 'normal',
  },

  "& .topSubHeading": {
      fontSize: '25px',
      fontWeight: 400,
      color: '#EDE6DC',
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      lineHeight: 'normal',
      maxWidth: '623px'
  },
  "& .edit_ProfileText": {
      top: '62.56px',
      position: 'absolute',
      fontWeight: 600,
      right: '50.5px',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '22px',
      display: 'flex',
      color: '#EDE6DC',
      gap: '10px',
      alignItems: 'center',
      zIndex: 1,
      cursor: 'pointer',
      "& .pendIcon": {
          width: '19.83px',
          height: '19.83px',
      }
  },

  "@media (max-width: 890px)": {
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
  },
  "@media (max-width: 760px)": {

      "& .topSubHeading": {
          paddingRight: '20px',
          paddingLeft: '20px',
      },
      "& .edit_ProfileText": { right: '35px', }
  },
  "@media (max-width: 560px)": {
      "& .edit_ProfileText": {
          width: 'fit-content',
          position: 'unset',
          margin: 'auto'
      }
  },
})
const FormBoxMain = styled(Box)({
  backgroundColor: '#EFEEE9',
  bottom: '16%',
  width: '766px',
  position: 'relative',
  left: '0px',
  display: 'flex',
  border: '1px solid #53503F',
  flexDirection: 'column',
  borderRadius: '4px',
  alignItems: 'center',
  padding: '50px 37.5px 119px 37.5px',
  boxSizing: 'border-box',
  "@media (max-width: 1050px)": { width: '90%', },
  "@media (max-width: 890px)": { width: '60%', bottom: '11%', },
  "@media (max-width: 700px)": { width: '80%' },

  "@media (max-width: 560px)": {
      width: '100%',
      borderTopLeftRadius: '25px',
      borderTopRightRadius: '25px',
      bottom: '120px',
  },
  "@media (max-width: 420px)": {
      padding: '50px 16px 119px 16px',
  }
})
const ProfileDetailsBoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'fit-content',
  alignItems: 'center',
  height: '100%',
  gap: '20px',
  "& .fourInputMainBox": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
  },
  "& .TwoInputBox": {
      display: 'flex',
      gap: '25px',
      alignItems: 'center',
  },
  "& .messageOuterBox": {
      width: '100%',
  },
  "& .selectOuterBox": {
      display: 'flex',
      alignItems: 'left',
      width: '100%',
      marginTop: '-20px'

  },
  "& .curveImage": {
      position: 'absolute',
      bottom: ' -62px',
      right: '22px',
  },

  "@media (max-width: 890px)": {
      alignItems: 'unset',
      "& .TwoInputBox": {
          flexDirection: 'column',
          alignItems: 'unset',
      },
      "& .fourInputMainBox": {
          alignItems: 'unset',
      },
      "& .selectOuterBox": {
          display: 'block'
      }
  },
  "@media (max-width: 420px)": {
      width: '100%',
  }

})
const InputFields = styled(TextField)({
  height: '56px',
  minWidth: "327px",
  borderRadius: '8px',
  '& input::placeholder': {
      opacity: 1,
      color: '#BABABA',
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
  },
  "& .MuiInputBase-root": {
      fontWeight: 600,
      color: "#53503F",
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      height: '100%',
      borderRadius: '8px',
  },
  "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px", borderRadius: '8px', },
  "& .MuiOutlinedInput-root": {
      borderRadius: '8px',
      "&:hover fieldset": {
      },
      "&.Mui-focused fieldset": {
          borderWidth: "0px",
      },
  },

  "& .MuiOutlinedInput-input": {
      backgroundColor: '#FFFFFF',
      padding: "17px 8px",
      boxSizing: 'border-box',
      borderRadius: '8px',
      height: '100%'
  },

  "& .MuiFormHelperText-contained": {
      marginRight: "0px",
      marginLeft: "0px",
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: 'normal',
      color: "#DC2626",
      fontFamily: "'Montserrat', sans-serif",
  },
  '& .css-1wc848c-MuiFormHelperText-root.Mui-error': {
      margin: '0px',
      color: "#DC2626",
      marginTop: '0.5px'
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": { border: '1px solid #DC2626' },
  '@media (max-width:420px )': {
      maxWidth: "unset",
      minWidth: "unset",

  },
});
const InputFieldsMultiLine = styled(TextField)({
  borderRadius: '8px',
  overflowY:'scroll',
  '& input::placeholder': {
      opacity: 1,
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",
      color: '#BABABA',
      fontWeight: 400,
  },
  "& .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::placeholder":{
      opacity: 1,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      fontWeight: 400,
      color: '#BABABA',
  },
  "& .MuiInputBase-root": {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      color: "#53503F",
      height: '100%',
      fontSize: '16px',

  },
  "& .MuiInputBase-multiline": {
      height: '100%'
  },
  "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px", },
  "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": { borderWidth: "0px", },
  },

  "& .MuiOutlinedInput-input": {
      padding: "17px 8px",
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '8px',
  },

  "& .MuiFormHelperText-contained": {
      marginRight: "0px",
      fontWeight: 600,
      marginLeft: "0px",
      lineHeight: 'normal',
      fontSize: '12px',
      fontFamily: "'Montserrat', sans-serif",
      color: "#DC2626",
      borderRadius: '8px',
  },
  '& .css-1wc848c-MuiFormHelperText-root.Mui-error': {
      margin: '0px',
      marginTop: '0.5px',
      color: "#DC2626",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": { border: '1px solid #DC2626' },
  "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root": {
      padding: '0px',
  },
  "& .css-1yhat2k-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input": {
      height: '140px !important'
  },

  '@media (max-width:660px )': {
      minWidth: "unset",
      maxWidth: "unset",

  },

});
const LabelTxt = styled(Typography)({
  fontWeight: 600,
  fontFamily: "'Montserrat', sans-serif",
  color: '#53503F',
  marginBottom: '4px',
  fontSize: '16px',
})

const ReactSelectField = styled(Select)({
  outline: 'none',
  minWidth: '327px',
  scrollbarGutter: 'stable',
  height: '56px',
  borderRadius: '8px',
  '& .custom-select__menu-list': {
      direction: 'ltr',
      scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
      borderRadius: '8px'
  },
  '& .custom-select__menu-list::-webkit-scrollbar': {
      scrollbarColor: '#53503F transparent',
      scrollbarWidth: '5px',
      direction: 'ltr',
  },
  '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      borderRadius: '8px',
      direction: 'ltr',
  },
  '& .custom-select__menu-list:hover': {
      direction: 'ltr',
      scrollbarWidth: '5px',
      borderRadius: '8px',
      scrollbarColor: '#53503F transparent',
  },
  "& .css-1uccc91-singleValue": {
      fontWeight: 600,
      color: "#53503F",
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
  },
  "& .css-1okebmr-indicatorSeparator": { display: 'none' },
  "& .css-yk16xz-control": {
      borderColor: 'none',
      border: 'none',
      height: '100%',
  },
  "& .css-yk16xz-control:hover": {
      borderColor: 'none',
      border: 'none',
  },
  "& .css-g1d714-ValueContainer": {
      border: 'none',
      height: '56px',
  },
  "& .css-1pahdxg-control": {
      outline: 'none !important',
      borderColor: 'transparent !important',
      border: 'none !important',
      boxShadow: 'none !important',
      minHeight: '56px',
      height: '56px',
      borderRadius: '8px',
  },
  "& .custom-select__control": {
      height: '56px',
      minHeight: '56px',
      borderRadius: '8px',
  },
  "& .custom-select__value-container": { height: '56px', },
  "& .css-1wa3eu0-placeholder": {
      color: '#BABABA',
      opacity: 1,
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",

  },
  "& .css-1hb7zxy-IndicatorsContainer": {
      display: 'flex',
      width: '24px',
      justifyContent: "center"
  },
  "& .css-tlfecz-indicatorContainer": { padding: '0' },
  "& .css-6q0nyr-Svg": {
      cursor: 'pointer',
      color: '#53503F',
  },
  "& .css-26l3qy-menu": {
      fontWeight: 400,
      fontFamily: "'Montserrat', sans-serif",
      color: '#3C3E49',
      zIndex: 1000,
      fontSize: '12px',
  },
  "& .css-9gakcf-option": {
      fontWeight: 600,
      backgroundColor: '#53503F',
      fontFamily: "'Montserrat', sans-serif",
      color: '#FFFFFF',
      fontSize: '12px',
  },
  "& span": { display: 'none' },
  "& svg": {
      position: 'absolute',
      cursor: 'pointer',
      top: '20px',
      right: '8px',
      color: '#53503F'
  },
  "@media (max-width: 400px)": { minWidth: 'unset' }
})

const ResponseModalBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  width: '600px',
  transform: 'translate(-50%, -50%) scale(0.89)',
  padding: '68px 49px',
  borderRadius: '4px',
  display: 'flex',
  boxSizing: 'border-box',
  backgroundColor: '#EFEEE9',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  "& .responseModalBox1": {
      height: '400px',
      maxHeight: '400px',

  },
  "& .responseModalBox": {
      height: '284px',
      maxHeight: '284px',

  },
  "& .contentText": {
      fontSize: '20px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      color: '#53503F',
      marginTop: '8px',
      maxWidth: '392px',
      textAlign: "center"
  },
  "& .contentText1": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '20px',
      fontWeight: 400,
      marginTop: '8px',
      color: '#53503F',
      textAlign: "center",
      maxWidth: '462px',
  },
  "& .contentText2": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '20px',
      color: '#53503F',
      fontWeight: 400,
      textAlign: "center",
      maxWidth: '462px',
  },
  "& .crossIconBox": {
      position: 'absolute',
      right: '28px',
      top: '28px',
      cursor: 'pointer'
  },
  "& .modalHeading": {
      fontSize: '22px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      color: '#53503F',
  },
  "& .modalHeading1": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '22px',
      color: '#53503F',
      fontWeight: 600,
      paddingBottom: '24px'
  },
  "@media  (max-width:550px)": {
      width: '100%',

  },
  '@media (max-width:400px )': {
      padding: '16.21px 25px 39.85px 25px',
      height: '215.57px',
      borderRadius: '4px',
      "& .modalHeading": {
          fontSize: '18px',
      },
      "& .contentText": {
          fontSize: '14px',
      },
      "& .crossIconBox": {
          right: '6px',
          top: '6px',
          "& img": {
              height: '24px',
              width: '24px'
          }
      }
  },
  '@media (max-width:362px )': {
      maxHeight: 'unset',
      height: 'unset',
  },
  '@media (max-width:359px )': {
      width: '100%',
  },
})
const MainButton = styled(Button)({
  height: '56px',
  width: '360px',
  backgroundColor: '#53503F',
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  cursor: 'pointer',
  fontWeight: 600,
  color: '#EDE6DC',
  "&:hover": { backgroundColor: '#53503F', },
  '@media (max-width:400px )': {
      height: '36px',
      width: '117px',
      borderRadius: '4px',
      fontSize: '12px',
  },
})
const ButtonParentBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginTop: '40px',
  gap: '50px',
  '@media (max-width:420px )': { gap: '28.45px', },
})
const PhoneNumStyle = styled(PhoneInput)({

  minWidth: "327px",
  boxSizing: "border-box",
  height: '56px',
  zIndex: 1,
  borderRadius: 8,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  color: "#53503F",
  paddingLeft: "8px",
  backgroundColor: '#FFFFFF',
  paddingRight: '1px',

  '& input': {
      border: 'none',
      fontWeight: 600,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      color: "#53503F",
  },
  '& input:focus': {
      border: 'none',
      outline: 'none'
  },
  '& .PhoneInputInput': {
      fontWeight: 600,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      color: "#53503F",
  },
  '& input:focus-visible': {
      border: 'none',
      outline: 'none'
  },
  "& .PhoneInputCountry": {
      marginRight: '8px',
      cursor: 'pointer'
  },
  '& .PhoneInputCountrySelectArrow': {
      color: '#53503F',
      borderLeft: '0px',
      borderTop: '0px',
      borderWidth: '2.8px',
      opacity: 2,
      fontSize: '22px',
      borderRadius: '.5px',
      marginLeft: '6.41px',
      cursor: 'pointer',
  },

  '@media (max-width:420px)': {
      minWidth: "unset",
      alignItems: 'unset',
  },


});
const SendMessageButtonParentBox = styled(Box)({
  marginTop: '30px'
})
const SendMessageButton = styled(Button)({
  width: '360px',
  backgroundColor: '#53503F',
  height: '56px',
  borderRadius: '4px',
  textTransform: 'none',
  fontSize: '18px',
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 600,
  cursor: 'pointer',
  color: '#EDE6DC',
  "&:hover": { backgroundColor: '#53503F', },
  '&.Mui-disabled': {
      backgroundColor: '#53503F',
      color: '#EDE6DC',
      opacity: 0.5,
      cursor: 'not-allowed'
  },
  '@media (max-width:400px )': {
      width: '100%',
      borderRadius: '4px',
      height: '36px',
      fontSize: '12px',
  },
})

const styles = {
  errorText: {
      fontWeight: 600,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
      color: "#DC2626",
      position: 'absolute' as 'absolute',
      lineHeight: 'normal',
      marginTop: '2px'
  },
  messageWordLimitText: {
      fontWeight: 600,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
      color: "rgba(83, 80, 63, 1)",
      lineHeight: 'normal',
      marginTop: '8px',
      textAlign: 'right' as 'right'
  }
}
// Customizable Area End
