import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {
    styled, Box, Button
  } from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CloseIcon from '@mui/icons-material/Close';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { getStorageData, removeStorageData } from '../../framework/src/Utilities';
import { useEffect, useState } from 'react';
import { MONTSERRAT_THIN } from './fontConstants.web';

const ExchangeICon=require("./socialmediaicons/exchange.svg").default
const MyOffersICon=require("./socialmediaicons/myoffers.svg").default
const MyWishesIcon=require("./socialmediaicons/mywishes.svg").default
const profileIcon=require ("./socialmediaicons/profile.svg").default
const closeIcon = require("./image_close.png").default;
const loginIcon = require("./group_login.png").default;
const profileAlternateImg = require("./socialmediaicons/group_.svg").default;

import LogOutConfirmationPopUp from './popups/LogOutConfirmationPopUp.web';
import { log } from 'console';
import {useNavigate} from 'react-router-dom'
export default function ProfilePopUp({handleLogin,showModal}: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [auth, setAuth] = useState<any>(null);
 const [loginData,setLogindata]=useState<any>({})
  const [confirmationModal,setConfirmationModal]=useState(false)
  const navigate =useNavigate()
  const handleConfirmationLogOut=()=>{
    setConfirmationModal(false)
  }
  const getLoginData=async()=>{
    const loginData= await getStorageData("loginDetails")
    setLogindata(JSON.parse(loginData))
  }
  useEffect(() => {
    const fetchAuth = async () => {
      const token = await getStorageData('loginToken'); 
      setAuth(token); 
      setAnchorEl(null)
    };
    getLoginData()
    fetchAuth(); 
  }, [auth,showModal]);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const handleLogOutModal=()=>{
  setAnchorEl(null)
  setConfirmationModal(true)
}
  const handleLogout = () => {
    setConfirmationModal(false)
    setAnchorEl(null);
    window.location.href="/";
    removeStorageData('loginToken')
    removeStorageData('loginDetails')
  }
  const handleNavigation = () => {
    
    if (loginData?.attributes?.role_name === 'personal_shopper') {
      navigate('/userProfileShopper')
    } else {
      navigate('/userProfileBuyer')
    }

  }
  const CustomTypoGraphy = styled(Typography)(({ theme }) => ({
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21.63px",
    textAlign: "left",
  }));

  const closeIconStyle = ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px',
    '>img:hover': {
    },
  })
  
  const loginBtn = {
    fontSize: '12px !important',
    backgroundColor: '#53503F',
    color: '#fff',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    }
  }
  const token=localStorage.getItem("loginToken")
  const loginDetails=localStorage.getItem("loginDetails")
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div
        onClick={(e: any) => handleClick(e)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img width={24} height={24} style={{cursor:"pointer"}} src={profileIcon} alt="profile" />
        <CustomTypoGraphy style={{ color:"#53503F",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          textAlign: "center",
          cursor:"pointer"
        }}>Profile</CustomTypoGraphy>
      </div>
      {showModal||!loginDetails ||!token||!auth ? 
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-root":{
            boxSizing:"border-box",
            width:"353px",
            height:"369px",
            overflowY: "hidden"
          },
          ".MuiPopover-paper": {
            borderRadius: "8px",
            boxSizing:"border-box",
            width: "353px",
            height:"300px",
            padding:"20px",
            overflowY: "hidden",
            margin:"0px",
            transform: "translateX(-65px) !important",
          },
        }}
      >
        <Box>
          <Box sx={closeIconStyle}><img src={closeIcon} alt='closeIcon' onClick={handleClose}/></Box>
          <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',padding: '5px'}}><img src={loginIcon} alt='login'/></Box>
            <Box><Typography sx={{fontSize: '14px', fontWeight: 600, color: '#53503F',textAlign: 'center',padding: '5px'}} className='montserratLightGoogle'>Login to your account</Typography></Box>
            <Box><Typography sx={{ fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,fontSize: '12px', fontWeight: 400, color: '#53503F',textAlign: 'center',padding: '5px'}} >You must be logged in to perform actions</Typography></Box>
            <Box>
              <Button
              onClick={handleLogin} 
              data-testid="loginTestId" variant="contained" 
                sx={loginBtn} className="montserratLightGoogle"
                >Login</Button>
              </Box>
          </Box>
        </Box>
      </Popover>: 
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-root":{
            boxSizing:"border-box",
            width:"353px",
            maxHeight:"413px",
            overflowY: "hidden"
          },
          ".MuiPopover-paper": {
            borderRadius: "8px",
            boxSizing:"border-box",
            width: "353px",
            maxHeight:"413px",
            padding:"20px",
            overflowY: "hidden",
            margin:"0px",
            transform: "translateX(-65px) !important",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            borderRadius: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop:"13px"
            }}
          >
            <div
              className="profileIcon"
              style={{ display: "flex", gap: "13px",cursor:'pointer',alignItems:"center"}}
              onClick={()=>handleNavigation()}
            >
              {loginData?.attributes?.avatar_url ? (
                <img
                  src={loginData.attributes.avatar_url}
                  alt="Profile"
                  style={{
                    borderRadius: "50%",
                    height: "44px",
                    width: "44px",
                  }}
                />
              ) : (
                <span style={{height:"44px",fontWeight:600,width:"44px",border:"1px solid #53503F",borderRadius:"50%",display:"flex",fontSize:"23px",justifyContent:"center",alignItems:"center"}}>
                {`${loginData?.attributes?.first_name?.charAt(0).toUpperCase() || ""}${loginData?.attributes?.last_name?.charAt(0).toUpperCase() || ""}`}
                </span>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "14px",
                    fontWeight: 800,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#53503F",
                  }}
                >
                  {loginData?.attributes?.first_name}{" "}{loginData?.attributes?.last_name}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#53503F",
                  }}
                >
                  Profile
                </Typography>
              </div>
            </div>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer",color:"#53503F" }} />
          </div>
          <hr style={{ color: "#53503F", marginTop: "27px" }} />
          <div
            style={{
              borderBottom: "0.5px solid #EDE6DC",
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:'pointer'
            }}
            onClick={() => navigate('/OrderManagement')}
          >
            <img src={MyOffersICon} alt="d" height={22} width={22} />
            <CustomTypoGraphy style={{color: "#53503F",cursor:'pointer'}} >My Orders</CustomTypoGraphy>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid #EDE6DC",
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:"pointer"
            }}
            onClick={()=>{
              setAnchorEl(null)
              navigate("/WishRequests")
            }}
          >
            <img src={MyWishesIcon} height={22} width={22} alt="wishes" />{" "}
            <CustomTypoGraphy style={{color: "#53503F",}}>My wishes</CustomTypoGraphy>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid #EDE6DC",
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:'pointer'
            }}
          >
            <LocalOfferOutlinedIcon style={{color:"#53503F"}} />{" "}
            <CustomTypoGraphy style={{color: "#53503F",}} onClick={() => navigate('/requestmanagement')}>My Offers</CustomTypoGraphy>
          </div>
         

          <div
            style={{
              borderBottom: "0.5px solid #EDE6DC",
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:'pointer'
            }}
          >
            <SettingsOutlinedIcon style={{ color: "#53503F" }} />{" "}
            <CustomTypoGraphy style={{color: "#53503F",}}>Settings</CustomTypoGraphy>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid #EDE6DC",
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:'pointer'
            }}
          >
            <HelpOutlineOutlinedIcon
             style={{color:"#53503F"}}
            />{" "}
            <CustomTypoGraphy style={{color:"#53503F"}}>Help</CustomTypoGraphy>
          </div>
          <div
            style={{
              padding: "9px 0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              cursor:"pointer"
            }}
          onClick={handleLogOutModal}>
            <LogoutOutlinedIcon style={{color:"#53503F", transform: "scaleX(-1)" }} />{" "}
            <CustomTypoGraphy style={{color: "#53503F",cursor:"pointer"}} >Log out</CustomTypoGraphy>
          </div>
        </div>
      </Popover> }
              <LogOutConfirmationPopUp
              open={confirmationModal}
              handleClose={handleConfirmationLogOut} 
              handleLogOut={handleLogout}
              />
    </div>
  );
}
