import React from "react";

// Customizable Area Start
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Pagination, 
  PaginationItem, Menu, MenuItem, Chip, FormControl, InputLabel, Select, TextField, InputAdornment, ListItemIcon
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { menuIcon, downArrow, rightArrow, filterIcon, searchIcon } from "./assets";
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import {AppHeader} from '../../../components/src/AppHeader.web';
import WithdrawOfferPopup from '../../../components/src/popups/withdrawPopupOffer.web';
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  searchBar() {
    return (
      <FormControl variant="outlined" fullWidth>
        <TextField
        data-testid="searchTestId"
        value={this.state.searchTerm}
        onChange={(event) => this.handleSearchChange(event.target.value)} 
        placeholder="Search Brands"
        variant="outlined"
        sx={{...webStyle.searchFieldStyle, backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={searchIcon} alt="searchIcon"/>
            </InputAdornment>
          ),
          endAdornment: null,
        }}
        fullWidth
        />
      </FormControl>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { anchorEl, openMenuIndex, offerListData } = this.state;

    return (
      <>
      <WithdrawOfferPopup data-testid="withdrawPopup" open={this.state.withdrawPopupOpen} handleClose={this.handleNotNowOption} handleLogOut={this.handleDeleteOfferRequest}/>
      <Box style={{backgroundColor:"rgb(237, 230, 220)", height:"100%"}}>
        <AppHeader data-testid="appHeaderTestId" offerFilter={this.state.offerFilter} offerFilterClose={this.handleCloseOfferFilter} offerFilterApply={this.handleApplyOfferButton}/>
        <Box style={webStyle.mainRequestTable} data-testid="tableMainTestId">
          <Typography data-testid="homeNavigateTestId" className="montserratFont" sx={webStyle.homeNavigate} onClick={this.handleNavigateToHome}>{configJSON.homeText}
            <img src={rightArrow} alt='rightIcon'/>
            <span style={{fontWeight: 500}}>{configJSON.offers}</span>
          </Typography>
          <Box style={webStyle.searchSortMain}>
            <Box sx={{ ...webStyle.filterSearchItem, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'normal', gap: 2 }}>
                <Button 
                  variant="contained" 
                  className="montserratFont"
                  sx={{...webStyle.actionBtn, width: '80px'}}
                  startIcon={<img src={filterIcon} alt="filterIcon" />}
                  data-testid="openFilterTestId"
                  onClick={this.handleOpenOfferFilter}
                >
                  {configJSON.filter} 
                </Button>
                <Box sx={{ flex: 4, width: '500px' }}> 
                  {this.searchBar()}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center',marginRight: '30px' }}>
                <FormControl style={webStyle.formControl}>
                  {this.state.sortOption ? '' : 
                  <InputLabel
                    className="montserratFont"
                    htmlFor="sortOption"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '10%',
                      transform: 'translateY(-50%)',
                      fontSize: '14px', 
                      color: 'rgba(83, 80, 63, 1)', 
                      pointerEvents: 'none', 
                    }}
                  >
                    Sort by
                  </InputLabel>
                  }
                  <Select
                    className="montserratFont"
                    name="sortOption"
                    value={this.state.sortOption}
                    data-testid="sortOptionTestId"
                    onChange={this.handleSortOption}
                    renderValue={(selected) => `Sort by: ${selected}`}  
                    sx={{
                      ...webStyle.select, 
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      border: '1px solid rgba(83, 80, 63, 1)',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none', 
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: webStyle.menuProps,
                      },
                    }}
                    inputProps={{ className: "montserratFont" }}
                  >
                    <MenuItem 
                      className="montserratFont"
                      value="Price: High To Low" 
                      data-testid="priceHightToLowTestId"
                      style={webStyle.menuItem} 
                    >
                      Price: High to Low
                    </MenuItem>
                    <MenuItem 
                      className="montserratFont"
                      value="Price: Low To High" 
                      data-testid="priceLowToHighTestId"
                      style={webStyle.menuItem} 
                    >
                      Price: Low to High
                    </MenuItem>
                    <MenuItem 
                      className="montserratFont"
                      value="Older" 
                      data-testid="priceHightToLowTestId"
                      style={webStyle.menuItem} 
                    >
                      Older
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box style={{margin: '15px 15px'}}>
            <TableContainer>
              <Table data-testid="tableTestId">
                <TableHead data-testid="tableHead">
                  <TableRow style={{ backgroundColor: '#ffffff', border: '1px solid rgba(83, 80, 63, 1)' }}>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.offerIdLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.productImageLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.dateLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.categoryLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.productNameLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.modalNumberLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.listedPriceLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.offerPriceLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.statusLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}>{configJSON.actionLabel}</TableCell>
                    <TableCell className="montserratFont" sx={{ ...webStyle.tableHeading, borderBottom: '1px solid rgba(83, 80, 63, 1)' }}></TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody data-testid="tableBodyTestId">
                  {offerListData.map((item: any, index: number) => 
                    <TableRow key={item.id} 
                    style={{ 
                      backgroundColor: index % 2 === 0 ? 'white' : 'rgba(239, 238, 233, 1)', 
                    }}
                    >
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.offer_id} </TableCell>
                      <TableCell>
                        <img src={item.attributes.variant_info.attributes.images[0].url} alt="Product" style={webStyle.productImage} />
                      </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.offer_create_date} </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.category_name} </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.product_name} </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.model_number} </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.order_price} </TableCell>
                      <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.offer_price} </TableCell>
                      <TableCell>
                        <Chip label={item.attributes.status}  sx={webStyle.chipStyle} />
                      </TableCell>
                      <TableCell>
                      {item.attributes.status === "accepted" ?
                        <Button 
                          variant="contained" 
                          className="montserratFont"
                          sx={webStyle.actionBtn}
                          endIcon={<ChevronRightIcon />}
                          data-testid="buyNowTestId"
                          onClick={() => this.handleBuyNow(item)}
                        >
                          Buy now
                        </Button> : null }
                      </TableCell>
                      <TableCell>
                      <img
                        data-testid="menuTestId"
                        src={menuIcon}
                        alt="More options"
                        onClick={(e) => this.handleMenuClick(e, index)}
                        style={{ cursor: 'pointer', width: 24, height: 24 }} 
                      />
                        <Menu
                          anchorEl={anchorEl}
                          open={openMenuIndex === index}
                          onClose={this.handleCloseMenu}
                          PaperProps={{
                            sx: { 
                              border: '1px solid rgba(83, 80, 63, 1)',
                              position: 'absolute',
                              top: 500,
                              left: 200,
                              zIndex: 1300
                            }
                          }}
                        >
                          <Box>
                          <MenuItem 
                          className="montserratFont" 
                          data-testid="withdrawOfferTestId"
                          sx={{...webStyle.tableData}} 
                          onClick={() => this.handleWithDrawOffer(item.id)}
                          >
                            <ListItemIcon><DeleteOutlineIcon/></ListItemIcon>
                            Withdraw Offer
                          </MenuItem>
                          <MenuItem 
                          className="montserratFont" 
                          data-testid="navigateToProductTestId"
                          sx={webStyle.tableData}
                          onClick={() => this.handleNavgiateToProductDescription(item.id)}
                          >
                            <ListItemIcon><RemoveRedEyeOutlinedIcon/></ListItemIcon>
                            Details
                          </MenuItem>
                          </Box>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Box style={webStyle.paginationMain}>
                <Box style={webStyle.dataCountMain}>
                  <Box className="montserratFont" style={webStyle.showCount}>Show count</Box>
                  <FormControl>
                    <Select
                      value={this.state.dataCount}
                      onChange={this.handleChange}
                      displayEmpty
                      open={this.state.countDropdown}
                      onClose={this.handleCountDropdownClose}
                      onOpen={this.handleCountDropdownOpen}
                      data-testid="dropdownTestId"
                      IconComponent={() => 
                        <img 
                          src={downArrow}
                          alt="arrow" 
                          data-testid="dropDownClickTest"
                          onClick={this.handleCountDropdownOpen}
                        />
                      }
                    >
                      <MenuItem 
                        value={5} 
                        sx={{
                          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                          '&.Mui-selected:hover': {
                            backgroundColor: '#5b5b5b',
                          },
                          '&.Mui-selected': {
                            color: '#fff',
                            backgroundColor: '#80806e',  
                          },
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                          }}>
                        <em>5</em>
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Pagination
                data-testid="paginationTestId"
                count={this.state.totalPages}
                sx={webStyle.pagination}
                onChange={this.handlePageChange}
                renderItem={(item) => {
                  if (item.type === 'previous') {
                    return (
                      <PaginationItem
                        {...item}
                        components={{
                          previous: () => (
                              <span>Prev</span>
                            ),
                        }}
                      />
                    );
                  }
                  if (item.type === 'next') {
                    return (
                      <PaginationItem
                        {...item}
                        components={{
                          next: () => <span>Next</span>
                        }}
                      />
                    );
                  }
                  return <PaginationItem {...item} />;
                }}
                />
              </Box>            
            </TableContainer>
          </Box>
        </Box>
      </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  productImage: {
    width: '50px', 
    height: '50px',
    border: '1px solid rgba(83, 80, 63, 1)',
    padding: '3px', 
    borderRadius: '4px',
    backgroundColor: 'rgba(239, 238, 233, 1)' 
  },
  downArrow: {   
    height: '20px',
    paddingLeft: '5px',
    paddingTop: '3px'
  },
  searchFieldStyle: {
    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  homeNavigate: {
    padding: '10px 15px',
    width: '30%', 
    fontSize: '18px', 
    display: 'flex'
  },
  mainRequestTable: {
    backgroundColor: 'rgba(237, 230, 220, 1)',
    height: '100vh'
  },
  tableHeading: {
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600, 
    fontSize: '14px'
  },
  tableData: {
    fontSize: "16px",
    fontWeight: 400, 
    marginBottom: "8px", 
    color: "rgba(83, 80, 63, 1)"
  },
  chipStyle: {
    backgroundColor: 'rgba(239, 238, 233, 1)', 
    color: 'rgba(83, 80, 63, 1)', 
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  actionBtn: {
    fontSize: '12px', 
    color: 'rgba(237, 230, 220, 1)', 
    backgroundColor: 'rgba(83, 80, 63, 1)', 
    padding: '4px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(239, 238, 233, 1)',
    color: 'rgba(83, 80, 63, 1)'
    },
    '&:focus': {
      outline: 'none', 
      boxShadow: 'none'
    },
    '&:active': {
      backgroundColor: 'inherit', 
    },
    '&.MuiButtonBase-root': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  paginationMain: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    backgroundColor: '#ffffff'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      backgroundColor: 'rgba(239, 238, 233, 1)'
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: 'rgba(83, 80, 63, 1)',
      color: 'rgba(255, 255, 255, 1)'
    },
    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
      borderRadius: 'none',
      backgroundColor: 'red',
      color: '#1976d2'
    },
  },
  showCount: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)',
    padding: '3px 0px'
  },
  dataCountMain: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  countNumber: {
    padding: '4px 0px'
  },
  count: {
    border: '1px solid rgba(239, 238, 233, 1)',
    display: 'flex',
    padding: '0px 15px',
    margin: '0px 15px'
  },
  menuProps: {
    borderRadius: 8,
    backgroundColor: '#f2f2e6',
  },
  menuItem: {
    fontSize: '14px',
    color: '#333',
    padding: '10px 20px',
    fontFamily: 'Montserrat, sans-serif',
    '&.Mui-selected:hover': {
      color: '#fff',
      backgroundColor: '#5b5b5b',
    },
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#80806e',  
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#e0e0e0',
    }
  },
  formControl: {
    minWidth: 160,
    borderRadius: 8,
    color: '#8c8c8c',
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
    backgroundColor: '#f2f2e6',
  },
  searchSortMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px',
    width: '100%'
  },
  filterSearchItem: {
    display: 'flex'
  }
};
// Customizable Area End
