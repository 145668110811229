import React from "react";

// Customizable Area Start
import CategoriesController, {
    Props,
  } from "./CategoriesController.web";
import { HeartIconOutlined, rightIcon,noProductIcon ,favHeartIcon} from "./assets";
import { Grid,styled, Card, CardContent, CardMedia, Typography, IconButton, Box, Button,Select, MenuItem, FormControl, InputLabel  } from '@mui/material';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class CategoriesWeb extends CategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
      {(this.state.product.length>0 && this.props.categoryData.length>0) &&<Box sx={webStyle.categoryMain} data-testid="categoryTestId">
        <Box display="flex" justifyContent="space-between" p={2}>
          <Typography className="montserratFont" data-test-id="homeNavigateBtn" onClick={this.props.handleShowCategory} sx={webStyle.homeNavigate}>Home<img src={rightIcon} alt='rightIcon'/><span style={{fontWeight: 500}}>{this.props.categoryName}</span></Typography>
          <Typography className="montserratFont">{this.state.productCount} Results</Typography>
        </Box> 
        <Box sx={webStyle.categoryBtn} p={2}>
          <Box sx={webStyle.filterData}>
          <Button 
          className="montserratFont"
          sx={webStyle.darkBtn} 
          onClick={this.props.allFilter}
          data-testid='allFilterTestId'
          >
            All Filters
          </Button>
          
          <Button 
          className="montserratFont"
          data-testid="womenTestId"
          sx={this.state.sortingByWomen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleWomenFilter}>
            Women
          </Button>

          <Button 
          className="montserratFont"
          data-testid="menTestId"
          sx={this.state.sortingByMen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleMenFilter}>
            Men
          </Button>
          </Box>
          <Box>
          <FormControl style={webStyle.formControl}>
            {this.state.sortOption ? '' : 
            <InputLabel
              className="montserratFont"
              htmlFor="sortOption"
              style={{
                position: 'absolute',
                top: '50%',
                left: '10%',
                transform: 'translateY(-50%)',
                fontSize: '14px', 
                color: 'rgba(83, 80, 63, 1)', 
                pointerEvents: 'none', 
              }}
            >
              Sort by
            </InputLabel>
            }
            <Select
              className="montserratFont"
              name="sortOption"
              value={this.state.sortOption}
              data-testid="sortOptionTestId"
              onChange={this.handleSortOption}
              renderValue={(selected) => `Sort by: ${selected}`}  
              sx={{
                ...webStyle.select, 
                backgroundColor: 'transparent',
                fontWeight: 400,
                border: '1px solid rgba(83, 80, 63, 1)',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none', 
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: webStyle.menuProps,
                },
              }}
              inputProps={{ className: "montserratFont" }}
            >
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="Production Year" 
              data-testid="productYearTestId"
              style={webStyle.menuItem} 
              onClick={this.handleProductionYearFilter}
              >
                Production Year
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont" value="Date Of Listing" style={webStyle.menuItem}>
                Date of Listing
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="Price: High To Low" 
              data-testid="priceHightToLowTestId"
              style={webStyle.menuItem} 
              onClick={this.handlePriceHighToLow}
              >
                Price: High to Low
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="Price: Low To High" 
              data-testid="priceLowToHighTestId"
              style={webStyle.menuItem} 
              onClick={this.handlePriceLowToHigh}
              >
                Price: Low to High
              </MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
      </Box>}
      <Grid container spacing={5} p={2} sx={{backgroundColor: "#EDE6DC"}}>
{(this.state.product.length > 0 && this.props.categoryData.length > 0)
? this.state.product.map((showProduct: any, index: any) => (
        <>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}> 
          <Card sx={webStyle.cardMain} data-test-id={`productContainer${index}`} onClick={()=>this.navigateToDetailedProductView(showProduct.attributes.catalogue_id)}> 
            <Box sx={webStyle.cardMediaMain}>
              <CardMedia
                component="img"
                image={showProduct.attributes.image_url} 
                alt='productName'
                sx={webStyle.cardImageMain}
              />
              <IconButton
                sx={webStyle.wishlistIcon}
                aria-label="add to wishlist"
                data-test-id='addToFav'
                onClick={(event) => {
                  event.stopPropagation()
                  this.handleAddToFavorite(showProduct?.id,showProduct?.attributes?.category_id)
              }}
              >
                <img src={ showProduct?.attributes?.is_favorited ? favHeartIcon : HeartIconOutlined} alt="heart"/>
              </IconButton>
            </Box>
            <CardContent sx={webStyle.cardTypography}> 
              <Typography variant="h6" className="montserratFont" sx={{ margin: 0, fontSize: '22px' }}>{showProduct.attributes.name}</Typography> 
              <Typography className="montserratFont" sx={{ margin: 0, fontSize: '16px',lineHeight: '30px' }} color="text.secondary"> 
                {showProduct.attributes.identity_name}
              </Typography> 
              <Typography variant="body1" sx={{ margin: 0, fontSize: '20px', fontWeight: 400 }}>{showProduct.attributes.price}</Typography> 
            </CardContent> 
          </Card> 
        </Grid> 
        </>
      )) : 
      <Box style={{width: '100%', textAlign: 'center'}}>
        <p>{
              <EmptyBagMainBox>
              <Box className='emptyImageText'>
                <img className="bagIcon" src={noProductIcon} alt="" />
                <Box className='emptyTextBox'>
                  <Typography className='emptyText'>No Results Found</Typography>
                  <Typography className='emptyText2'>No results found. Please try again.</Typography>
                  <Button data-test-id='addProductTestId'
                  style={{height:"56px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                  }}
                  className="startShopping" onClick={()=>{this.props.handleShowCategory()}} variant="contained">
                  Back to Home
                  </Button>
                </Box>
              </Box>
            </EmptyBagMainBox>
          }</p>
      </Box>
      }
      </Grid> 
    </>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const EmptyBagMainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin:"7rem 0px",
  "& .emptyImageText": {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '30px'
  },
  "& .emptyTextBox": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '15px'
  },
  "& .emptyText": {
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '25px',
      fontWeight: 600,
      color: '#53503F',
  },
  "& .emptyText2": {
      fontFamily: "'Montserrat', sans-serif",
      textAlign: 'center',
      fontSize: '20px',
      color: '#53503F',
      fontWeight: 400,
      maxWidth: '338px'
  },
  "& .startShopping": {
      textTransform: 'none',
      borderRadius: '4px',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      backgroundColor: '#53503F',
      color: '#EFEEE9',
      fontWeight: 600,
      cursor: 'pointer',
      width: '283px',
  },
  "& .startShopping:hover": {
      backgroundColor: '#53503F',
  },
  "@media  (max-width:500px)": {
      '& .startShopping': {
          width: 'unset',
          fontWeight: 500,
          fontSize: '14px',
      },
      "& .emptyText": {
          fontSize: '16px',
      },
      "& .emptyText2": {
          fontSize: '14px',
      },
      "& .bagIcon":{
          height:'100px',
          width:'100px'
      }
  }
})
const webStyle = {
  homeNavigate: {
    width: '30%', 
    fontSize: '18px', 
    display: 'flex',
    cursor:"pointer"
  },
  filterData: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px'
  },
  categoryMain: {
    backgroundColor: "#EDE6DC",
  },
  categoryBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px'
  },
  transparentBtn: {
    border: '1px solid #53503F',
    color: '#53503F',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  darkBtn: {
    border: '1px solid #53503F',
    backgroundColor: '#53503F',
    color: '#ffffff',
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  cardMain: {
    maxWidth: '100%', 
    boxShadow: 'none',
    border: 'none',
    cursor:"pointer"
  },
  cardMediaMain: {
    position: 'relative'
  },
  cardImageMain: {
    width: 200,
    height: 300,
    objectFit: 'contain',
    margin: '16px auto',
    borderRadius: 4,
  },
  wishlistIcon: {
    position: 'absolute',
    top: 0,
    right: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  cardTypography: {
    backgroundColor: '#EDE6DC', 
    margin: 0, 
    padding: '10px 0px'
  },
  formControl: {
    minWidth: 160,
    borderRadius: 8,
    color: '#8c8c8c',
    fontSize: '14px',
  },
  inputLabel: {
    color: '#8c8c8c',
  },
  select: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
    backgroundColor: '#f2f2e6',
  },
  menuItem: {
    fontSize: '14px',
    color: '#333',
    padding: '10px 20px',
  },
  menuProps: {
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
  },
};
// Customizable Area End