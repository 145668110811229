Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email*";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name*";
exports.lastName = "Last name*";
exports.labelEmail = "Email";
exports.labelPassword = "Password*";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.locationText = "Location*"
exports.confirmPassword = "Confirm Password*"
exports.registrationEndPoint = "account_block/accounts";
exports.locationEndPoint = "/account_block/accounts/country_code_and_flags.json"
exports.firstNameError = "First user name is required"
exports.lastNameError = "Last user name is required"
exports.emailRequired = "Email is required";
exports.emailInvalid = "Invalid email";
exports.locationRequired = "Location is required"
exports.requiredPassword = "Password is required"
exports.invalidPassword = "Password must of 8 character that includes one uppercase letter, one lowercase letter, and one special character";
exports.confirmPasswordRequired = "Password is required"
exports.confirmPasswordInvalid = "Password and confirm password does not match";
exports.dateRequired = "Please select date"
exports.genderRequired = "Please select gender"
exports.workEmailLabel = "Work Email*"
exports.phoneNumber = "Phone Number*"
exports.sellerRegistrationEndPoint = ""
exports.addressLine1Label = "Address Line 1"
exports.addressLine2Label = "Address Line 2"
exports.addressPlaceholder = "Your address"
exports.cityLabel = "City"
exports.cityApiEndPoint = "account_block/accounts/country_code_and_flags/show_cities?country_code="
exports.uploadDocumentsLabel = "Upload commercial registration permit"
exports.formDataContent = "multipart/form-data"
exports.submitEndPoint = "account_block/accounts/signup_form2"
exports.comRegistrationLabel = "Commercial Registration Number"
exports.comRegistrationPlaceholder = "Enter here"
exports.addressLine1Require = "Address Line 1 required"
exports.addressLine2Require = "Address Line 2 required"
exports.cityError = "City is required"
exports.comRegistrationRequired = "Commercial Registration Number"
exports.otpConfirmationEndPoint = "account_block/accounts/email_confirmations"
exports.resendOtpEndPoint = "account_block/accounts/send_otps"
exports.firstNamePlaceholder = "Your full name"
exports.lastNamePlaceholder = "Your last name"
exports.emailPlaceholder = "Your email"
exports.placeholderPass = "Your password"
exports.placeholderConfirmPass = "Confirm your password"
exports.firstNamePlaceholder = "Your full name";
exports.lastNamePlaceholder = "Your last name";
exports.workEmailPlaceholder = "Your email";
exports.phoneNumberPlaceholder = "Your phone number";
exports.addresssPlaceholder = "Your address";
exports.commercialRegistrationNum = "Enter here";
exports.uploadDocumentLabel = "Upload commercial registration permit";
// Customizable Area End
